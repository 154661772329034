import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumericDecimalOnly]'
})
//<input type="text"  appNumericDecimalOnly decimals="2" >
export class NumericDecimalOnlyDirective {
  @Input("decimals") decimals: number = 2;

  private check(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^-?(0|[1-9]\d*)?$/));
    } else {
      // var regExpString =
      //   "^\\s*((\\d+(\\.\\d{0," +
      //   this.decimals +
      //   "})?)|((\\d*(\\.\\d{1," +
      //   this.decimals +
      //   "}))))\\s*$";
      var regExpString =
        "^((\\d+(\\.\\d{0," +
        this.decimals +
        "})?)|((\\d*(\\.\\d{1," +
        this.decimals +
        "}))))$";
      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue: any) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;
      if (currentValue !== '' && !this.check(currentValue)) {
        this.el.nativeElement.value = oldValue;
      }
    });
  }

  constructor(private el: ElementRef) { }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("blur", ["$event"])
  onBlur(event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    this.run(this.el.nativeElement.value);
  }
}
