import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appStartWithoutSpace]'
})
export class StartWithoutSpaceDirective {

  constructor(private _el: ElementRef) { }
  private run(event: any) {
    setTimeout(() => {
      const initalValue = this._el.nativeElement.value.trimStart();
      //this._el.nativeElement.value = initalValue.replace(/^[^a-zA-Z0-9_\s-]+$/g, '');
      this._el.nativeElement.value = this._el.nativeElement.value.trimStart();
      if (this._el.nativeElement.value == '' || this._el.nativeElement.value == ' ' || this._el.nativeElement.value == '  ') {
        this._el.nativeElement.value = null;
      }

      // if (initalValue !== this._el.nativeElement.value) {
      //   event.stopPropagation();
      // }
    });
  }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    this.run(event);
  }
  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run(event);
  }
  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    this.run(event);
  }

  @HostListener("blur", ["$event"])
  onBlur(event: KeyboardEvent) {
    this.run(event);
  }
  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(event);
  }
}
